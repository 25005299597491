import isEqual from 'lodash.isequal';

export const arraysAreEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (typeof sortedArr1[i] === 'string') {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    } else {
      if (!isEqual(sortedArr1[i], sortedArr2[i])) return false;
    }
  }

  return true;
};
