import { STANDARD_DATE_FORMAT } from '@variable';
import { arraysAreEqual } from '@util';
import { AdvancedFilterProps } from '@page/AppTransactions/index.interface';

export const disableFilterButton = (
  initialValues: AdvancedFilterProps,
  currentValues: AdvancedFilterProps,
) => {
  const newStartDate = currentValues.startDate
    ? currentValues.startDate.format(STANDARD_DATE_FORMAT)
    : '';
  const newEndDate = currentValues.endDate
    ? currentValues.endDate.format(STANDARD_DATE_FORMAT)
    : '';

  const initialStartDate = initialValues.startDate
    ? initialValues.startDate.format(STANDARD_DATE_FORMAT)
    : '';
  const initialEndDate = initialValues.endDate
    ? initialValues.endDate.format(STANDARD_DATE_FORMAT)
    : '';

  return (
    (newStartDate && !newEndDate) ||
    (newEndDate && !newStartDate) ||
    (newStartDate === initialStartDate &&
      newEndDate === initialEndDate &&
      arraysAreEqual(currentValues.apps, initialValues.apps))
  );
};
