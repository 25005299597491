import { Button, ClickAwayListener, Menu } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as FilterIcon } from '@asset/icons/filter.svg';
import { useAppSelector } from '@hook/hooks.hook';
import { selectIsFetchingCompanyAppTransactions } from '@selector/company.selector';
import AdvancedFilter from '@page/AppTransactions/AdvancedFilter';
import { selectApps, selectIsLoadingApps } from '@selector/app.selector';
import { AdvancedFilterProps } from '@page/AppTransactions/index.interface';
import { arraysAreEqual } from '@util';

const FilterButton = ({
  onSetFilter,
  defaultSelectedApp,
}: {
  onSetFilter: (values: any) => void;
  defaultSelectedApp: string[];
}) => {
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const apps = useAppSelector(selectApps);
  const [appOptions, setAppOptions] = useState<any[]>([]);

  useEffect(() => {
    setAppOptions(
      apps.map((app) => ({
        title: `${app.name} - ${app.fiatCurrency}`,
        value: app.id,
      })),
    );
  }, [apps]);

  const isLoadingApps = useAppSelector(selectIsLoadingApps);

  const [filterOptions, setFilterOptions] = useState<AdvancedFilterProps>({
    startDate: undefined,
    endDate: undefined,
    apps: defaultSelectedApp,
  });

  useEffect(() => {
    const newApps = appOptions.map((app) => app.value);
    if (
      !arraysAreEqual(newApps, filterOptions.apps) &&
      !defaultSelectedApp.length
    ) {
      setFilterOptions((values) => ({
        ...values,
        apps: newApps,
      }));
    }
  }, [appOptions, filterOptions, defaultSelectedApp]);

  const isFetchingCompanyAppTransactions = useAppSelector(
    selectIsFetchingCompanyAppTransactions,
  );

  const onClearFilter = useCallback(
    async (setFieldValue: any, onSubmit: any) => {
      await setFieldValue({
        startDate: undefined,
        endDate: undefined,
        apps: appOptions.map((app) => app.value),
      });
      onSubmit();
      handleCloseFilterMenu();
    },
    [appOptions],
  );

  const handleOpenFilterMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setFilterAnchorEl(null);
  };

  const isOpen = Boolean(filterAnchorEl);

  return (
    <ClickAwayListener onClickAway={handleCloseFilterMenu}>
      <>
        <Button
          variant="contained"
          startIcon={<FilterIcon />}
          onClick={handleOpenFilterMenu}
          disabled={isFetchingCompanyAppTransactions || isLoadingApps}
        >
          Filter
        </Button>

        {isOpen && (
          <Menu
            open={isOpen}
            id="filter-menu"
            anchorEl={filterAnchorEl}
            sx={{ p: 0, ul: { p: 0 } }}
            onClose={handleCloseFilterMenu}
          >
            <AdvancedFilter
              appOptions={appOptions}
              onSetFilter={onSetFilter}
              initialValues={filterOptions}
              onClearFilter={onClearFilter}
              onClose={handleCloseFilterMenu}
              setInitialValues={setFilterOptions}
            />
          </Menu>
        )}
      </>
    </ClickAwayListener>
  );
};

export default FilterButton;
