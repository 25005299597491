import { Button, Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { AdvancedFilterProps } from '@page/AppTransactions/index.interface';
import { disableFilterButton } from '@page/AppTransactions/util';

const ActionButtons = ({
  initialValues,
  onClearFilter,
}: {
  initialValues: AdvancedFilterProps;
  onClearFilter: (setFieldValue: any, onSubmit: any) => void;
}) => {
  const { values, isValid, setValues, submitForm } =
    useFormikContext<AdvancedFilterProps>();

  const handleClearFilter = useCallback(() => {
    onClearFilter(setValues, submitForm);
  }, [onClearFilter, setValues, submitForm]);

  return (
    <Stack justifyContent="flex-end" direction="row" gap={1}>
      <Button variant="outlined" onClick={handleClearFilter}>
        Clear
      </Button>

      <Button
        variant="contained"
        disabled={!isValid || disableFilterButton(initialValues, values)}
        type="submit"
      >
        Apply
      </Button>
    </Stack>
  );
};

export default ActionButtons;
