import React, { Fragment } from 'react';
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { Field, FieldArray, useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';

import { LIFESPAN_OPTION } from '@variable';
import { AppMode } from '@enum/appMode.enum';
import { useAppSelector } from '@hook/hooks.hook';
import { selectApps } from '@selector/app.selector';

const PayLinkForm = () => {
  const { values, touched, errors, getFieldProps } = useFormikContext<{
    appId: string;
    email: string;
    amount: string;
    extraDetails: any[];
  }>();
  const navigate = useNavigate();
  const apps = useAppSelector(selectApps);
  const appOptions = apps
    .filter((app) => app.mode !== AppMode.DEVELOPMENT)
    .map((app) => ({
      title: `${app.name} - ${app.fiatCurrency}`,
      value: app.id,
    }));

  const fiatCurrency = apps?.find(
    (app) => app.id === values.appId,
  )?.fiatCurrency;

  return (
    <Stack spacing={3} px={2}>
      <Typography variant="body1" fontWeight={400} color="grey.700">
        Quickly create and send payment links, allowing your customers to
        complete transactions with ease.
      </Typography>

      {appOptions.length ? (
        <Stack
          flexWrap="wrap"
          justifyContent="space-between"
          gap={2}
          direction="row"
        >
          <FormControl variant="outlined" sx={{ width: '48%' }}>
            <InputLabel>App</InputLabel>
            <Field as={Select} label="App" {...getFieldProps('appId')}>
              {appOptions.map(({ value, title }) => (
                <MenuItem key={value} value={value}>
                  {title}
                </MenuItem>
              ))}
            </Field>
          </FormControl>

          {/* Email Field */}
          <TextField
            label="Customer Email"
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            sx={{ width: '48%' }}
            disabled={!values.appId}
            {...getFieldProps('email')}
          />

          {/* Amount Field */}
          <TextField
            label="Amount"
            type="number"
            sx={{ width: '48%' }}
            error={touched.amount && Boolean(errors.amount)}
            helperText={touched.amount && errors.amount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{fiatCurrency}</InputAdornment>
              ),
            }}
            disabled={!values.appId}
            {...getFieldProps('amount')}
          />

          <FormControl
            variant="outlined"
            sx={{ width: '48%' }}
            disabled={!values.appId}
          >
            <InputLabel>Lifespan</InputLabel>
            <Field as={Select} label="Lifespan" {...getFieldProps('lifespan')}>
              {LIFESPAN_OPTION.map(({ value, title }) => (
                <MenuItem key={value} value={value}>
                  {title}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Stack>
      ) : (
        <Alert severity="error">
          It looks like you don’t have any apps in production yet. To get
          started,{' '}
          <Link onClick={() => navigate('/dashboard/apps')}>create an app</Link>{' '}
          and publish it to production. Once published, you’ll be ready to
          proceed!
        </Alert>
      )}

      {Boolean(values.appId) && (
        <>
          {/* Extra Details Section */}
          <Typography variant="body1" mt={4}>
            Extra Details (Optional)
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={2}>
            Add any additional details for your transaction as key-value pairs.
          </Typography>

          <FieldArray name="extraDetails">
            {({ remove, insert }) => (
              <>
                {values.extraDetails.map((_, index) => {
                  const touch = (touched.extraDetails as any)?.[index];
                  const error = (errors.extraDetails as any)?.[index];

                  return (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={2}
                      alignItems="flex-start"
                    >
                      <TextField
                        label={`Key`}
                        error={Boolean(touch?.key && error?.key)}
                        helperText={touch?.key && error?.key}
                        sx={{ width: '45%' }}
                        {...getFieldProps(`extraDetails[${index}].key`)}
                      />
                      <TextField
                        label={`Value`}
                        sx={{ width: '45%' }}
                        error={touch?.value && Boolean(error?.value)}
                        helperText={touch?.value && error?.value}
                        {...getFieldProps(`extraDetails[${index}].value`)}
                      />

                      <IconButton onClick={() => remove(index)}>
                        <RemoveIcon />
                      </IconButton>
                    </Stack>
                  );
                })}

                <Stack alignItems="flex-start">
                  <Button
                    onClick={() =>
                      insert(values.extraDetails.length, { key: '', value: '' })
                    }
                    variant="contained"
                    size="medium"
                    sx={{ background: '#DFEFF9 !important', color: '#0C2A35' }}
                  >
                    Add Extra Details
                  </Button>
                </Stack>
              </>
            )}
          </FieldArray>
        </>
      )}
    </Stack>
  );
};

export default PayLinkForm;
