import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { Search } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  downloadCompanyTransactions,
  getCompanyTransactions,
  setPageTitle,
  setTransactionModalId,
} from '@action/company.action';
import { useAppDispatch } from '@hook/hooks.hook';
import { selectCompanyTransactions } from '@selector/company.selector';
import TransactionStatus from '@page/Overview/TransactionStatus';
import SortByMenu from '@page/AppTransactions/SortByMenu';
import ExportAs from '@page/AppTransactions/ExportAs';
import { fetchApps } from '@action/app.action';

import FilterButton from './FilterButton';

const HEADER = ['ID', 'App', 'Type', 'Amount', 'Date Created', 'Status'];

const AppTransactions = () => {
  const transactions = useSelector(selectCompanyTransactions);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const dispatch = useAppDispatch();
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [searchParams] = useSearchParams();
  const appId = searchParams.get('appId');
  const [appIds, setAppIds] = useState<string[]>(appId ? [appId] : []);
  const [sortByMenuValue, setSortByMenuValue] = useState(0);
  const sortDirection = sortByMenuValue === 0 ? 'DESC' : 'ASC';

  const handleSearch = useCallback(
    async (values?: any) => {
      const { page: newPage, rows: newRowsPerPage } = values || {};
      await dispatch(
        getCompanyTransactions({
          page: newPage || page || 1,
          limit: newRowsPerPage || rowsPerPage,
          searchQuery: searchTerm,
          sortDirection,
          startDate,
          endDate,
          appIds,
        }),
      );
      setSearchPerformed(true);
    },
    [
      dispatch,
      page,
      rowsPerPage,
      searchTerm,
      sortDirection,
      startDate,
      endDate,
      appIds,
    ],
  );

  useEffect(() => {
    dispatch(setPageTitle('Transactions'));
    dispatch(
      getCompanyTransactions({
        page: 1,
        limit: rowsPerPage,
        sortDirection,
        startDate,
        endDate,
        appIds,
      }),
    );
    dispatch(fetchApps());
    setSearchPerformed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = async (_: unknown, newPage: number) => {
    setPage(newPage);
    await handleSearch({ page: newPage + 1 });
  };

  const handleRowsPerPageChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(newRowsPerPage);
      setPage(0);
      await handleSearch({ rows: newRowsPerPage, page: 1, appIds });
    },
    [handleSearch, appIds],
  );

  const setTransactionId = (id: string) => {
    dispatch(setTransactionModalId(id));
  };

  const handleSortBy = useCallback(
    async (value: number) => {
      setSortByMenuValue(value);

      if (value !== sortByMenuValue)
        await dispatch(
          getCompanyTransactions({
            page: page || 1,
            limit: rowsPerPage,
            searchQuery: searchTerm,
            sortDirection: value === 0 ? 'DESC' : 'ASC',
            startDate,
            endDate,
            appIds,
          }),
        );
    },
    [
      dispatch,
      page,
      rowsPerPage,
      searchTerm,
      sortByMenuValue,
      startDate,
      endDate,
      appIds,
    ],
  );

  const onSetFilter = useCallback(
    async ({
      startDate,
      endDate,
      appIds,
    }: {
      startDate: string;
      endDate: string;
      appIds: string[];
    }) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setAppIds(appIds);

      await dispatch(
        getCompanyTransactions({
          page: page || 1,
          limit: rowsPerPage,
          searchQuery: searchTerm,
          sortDirection,
          startDate,
          endDate,
          appIds,
        }),
      );
    },
    [page, dispatch, searchTerm, rowsPerPage, sortDirection],
  );

  const [isExportSuccessFull, setIsExportSuccessFull] = useState(false);

  const onCloseSuccessModal = () => {
    setIsExportSuccessFull(false);
  };

  const handleDownloadReport = useCallback(async () => {
    if (!transactions?.data?.length) return;

    const res: any = await dispatch(
      downloadCompanyTransactions({
        searchQuery: searchTerm,
        sortDirection,
        startDate,
        endDate,
        appIds,
      }),
    );

    if (res.error)
      throw new Error('Download request failed, please try again later!');

    setIsExportSuccessFull(true);
  }, [
    appIds,
    searchTerm,
    sortDirection,
    startDate,
    endDate,
    transactions,
    dispatch,
  ]);

  return (
    <Box mb={10}>
      <Toolbar sx={{ justifyContent: 'space-between', my: 3 }}>
        <TextField
          variant="outlined"
          placeholder="Search by ID, Blockchain ID or Crypto address"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setSearchPerformed(false);
          }}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          sx={{ width: '100%', maxWidth: '500px' }}
        />

        <Stack direction="row" gap={2}>
          <ExportAs
            onDownloadReport={handleDownloadReport}
            isExportSuccessFull={isExportSuccessFull}
            onCloseSuccessModal={onCloseSuccessModal}
            hasData={Boolean(transactions?.data?.length)}
          />

          <SortByMenu onSortBy={handleSortBy} value={sortByMenuValue} />

          <FilterButton onSetFilter={onSetFilter} defaultSelectedApp={appIds} />
        </Stack>
      </Toolbar>

      {!searchPerformed && (
        <Box p={3}>
          <Typography variant="body1" color="text.secondary">
            Press <b>ENTER</b>
          </Typography>
        </Box>
      )}

      {!transactions?.data?.length && searchPerformed && (
        <Box p={3}>
          <Typography variant="body1" align="center" color="text.secondary">
            No results {searchTerm && <>for &quot;{searchTerm}&quot;</>}
          </Typography>
        </Box>
      )}

      {searchPerformed &&
        Boolean(searchTerm) &&
        Boolean(transactions?.data?.length) && (
          <Box p={2}>
            <Typography>
              Search result(s) for &quot;<b>{searchTerm}</b>&quot;
            </Typography>
          </Box>
        )}

      {Boolean(transactions?.data?.length) && searchPerformed && (
        <TableContainer sx={{ px: 3 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: 'rgba(223, 239, 249, 0.20)' }}>
                {HEADER.map((header) => (
                  <TableCell key={header} sx={{ border: 0 }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {transactions?.data.map(
                ({
                  appName,
                  cryptocurrency,
                  cryptocurrencyAmount,
                  updatedAt,
                  status,
                  type,
                  id,
                  uniqueId,
                }) => (
                  <TableRow
                    key={id}
                    onClick={() => setTransactionId(id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Tooltip title={uniqueId}>
                      <TableCell
                        sx={{
                          borderColor: 'grey.200',
                          textTransform: 'uppercase',
                        }}
                      >
                        <>
                          {(uniqueId?.substring(0, 2) || '') +
                            '...' +
                            (uniqueId?.substring(uniqueId.length - 5) || '')}
                        </>
                      </TableCell>
                    </Tooltip>

                    <TableCell sx={{ borderColor: 'grey.200' }}>
                      {appName}
                    </TableCell>

                    <TableCell
                      sx={{
                        borderColor: 'grey.200',
                        textTransform: 'capitalize',
                      }}
                    >
                      {type === 'outgoing' ? 'Withdrawal' : type}
                    </TableCell>
                    <TableCell sx={{ borderColor: 'grey.200' }}>
                      {Number(cryptocurrencyAmount).toLocaleString('en-US', {
                        maximumFractionDigits: 6,
                      })}{' '}
                      {cryptocurrency}
                    </TableCell>
                    <TableCell sx={{ borderColor: 'grey.200' }}>
                      {dayjs(updatedAt).format('hh:mm A MMMM D, YYYY')}
                    </TableCell>
                    <TableCell sx={{ borderColor: 'grey.200' }}>
                      <TransactionStatus status={status} />
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={transactions?.totalRecords || 0}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPageOptions={[30, 60, 100]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            slotProps={{
              actions: {
                previousButton: { 'aria-label': 'Previous Page' },
                nextButton: { 'aria-label': 'Next Page' },
              },
            }}
          />
        </TableContainer>
      )}
    </Box>
  );
};

export default AppTransactions;
