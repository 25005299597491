import React from 'react';
import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material';

import { useAppSelector } from '@hook/hooks.hook';
import { selectApp } from '@selector/app.selector';
import { JOB_LIFESPANS_LONG } from '@variable';

const PayLinkPreview = ({
  appId,
  email,
  amount,
  lifespan,
  extraDetails,
}: any) => {
  const app = useAppSelector((state) => selectApp(state, appId));

  return (
    <>
      <Alert severity="warning" sx={{ mb: 3 }}>
        <AlertTitle>Please ensure all details are correct.</AlertTitle>
        Note that this payment link is valid for only{' '}
        {JOB_LIFESPANS_LONG[lifespan]} and can be used for a single payment
        only.
      </Alert>

      <Stack flexWrap="wrap" direction="row" rowGap={8} mb={10}>
        <Box px={2} width={'50%'}>
          <Typography variant="caption">App</Typography>
          <Typography>{app?.name}</Typography>
        </Box>
        <Box px={2} width={'50%'}>
          <Typography variant="caption">Customer Email</Typography>
          <Typography>{email}</Typography>
        </Box>
        <Box px={2} width={'50%'}>
          <Typography variant="caption">Amount</Typography>
          <Typography>
            {app?.fiatCurrency}{' '}
            {Number(amount).toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Box>
      </Stack>
      {Boolean(extraDetails.length) && (
        <Stack>
          <Typography variant="body1">Extra Details</Typography>

          <Typography variant="body2" color="textSecondary" mb={3}>
            These will be included in the details object sent to your webhook
            URL when triggered.
          </Typography>

          <Stack flexWrap="wrap" direction="row" rowGap={8} mb={10}>
            {extraDetails.map(({ key, value }: any, index: number) => (
              <Box px={2} width={'50%'} key={index}>
                <Typography variant="caption">{key}</Typography>
                <Typography>{value}</Typography>
              </Box>
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default PayLinkPreview;
