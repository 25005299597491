import { Stack } from '@mui/material';
import React from 'react';

import AppSelector from '@page/AppTransactions/AppSelector';
import CalendarPicker from '@page/AppTransactions/CalendarPicker';
import ActionButtons from '@page/AppTransactions/ActionButtons';
import { AdvancedFilterProps } from '@page/AppTransactions/index.interface';

const FilterFormFields = ({
  appOptions,
  initialValues,
  onClearFilter,
}: {
  appOptions: { title: string; value: string }[];
  initialValues: AdvancedFilterProps;
  onClearFilter: (setFieldValue: any, onSubmit: any) => void;
}) => {
  return (
    <Stack gap={3}>
      <CalendarPicker />

      {appOptions.length && <AppSelector options={appOptions} />}

      <ActionButtons
        initialValues={initialValues}
        onClearFilter={onClearFilter}
      />
    </Stack>
  );
};

export default FilterFormFields;
