import { Field, useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';
import React from 'react';

import { AdvancedFilterProps } from '@page/AppTransactions/index.interface';

const CalendarPicker = () => {
  const {
    values: { startDate },
  } = useFormikContext<AdvancedFilterProps>();

  return (
    <Stack gap={2} direction="row" justifyContent="space-between">
      <Field
        name="startDate"
        component={({ field, form }: any) => (
          <DatePicker
            label="Start"
            value={field.value ? dayjs(field.value) : undefined}
            onChange={(value) => form.setFieldValue(field.name, value)}
            shouldDisableDate={(date) => {
              return dayjs(date).isAfter(dayjs());
            }}
          />
        )}
      />

      <Field
        name="endDate"
        component={({ field, form }: any) => (
          <DatePicker
            label="End"
            value={field.value ? dayjs(field.value) : undefined}
            onChange={(value) => form.setFieldValue(field.name, value)}
            shouldDisableDate={(date) => {
              return Boolean(
                dayjs(date).isAfter(dayjs()) ||
                  (startDate &&
                    dayjs(date).isBefore(dayjs(startDate).add(1, 'days'))),
              );
            }}
          />
        )}
      />
    </Stack>
  );
};

export default CalendarPicker;
