import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material';
import React, { MouseEvent, useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Avatar } from '@asset/icons/avatar.svg';
import { ReactComponent as ArrowDown } from '@asset/icons/arrowDown.svg';
import { ReactComponent as LogoutIcon } from '@asset/icons/logout.svg';
import { ReactComponent as MenuAvatar } from '@asset/icons/menuAvatar.svg';
import { ORIGIN_URL } from '@variable';

const HeaderMenu = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = useCallback(() => {
    logout({
      logoutParams: { returnTo: ORIGIN_URL + '?action=logout' },
    });
    handleClose();
  }, [logout]);

  const goToProfile = useCallback(() => {
    navigate('/dashboard/settings');
    handleClose();
  }, [navigate]);

  return (
    <>
      <Button onClick={handleClick} variant="text">
        <Stack
          flexDirection="row"
          gap="8px"
          sx={{ cursor: 'pointer' }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            height={32}
            width={32}
            bgcolor="grey.200"
            borderRadius="50%"
          >
            <Box color={'grey.600'} height={21}>
              <Avatar />
            </Box>
          </Stack>

          <Box color={'primary.900'}>
            <ArrowDown />
          </Box>
        </Stack>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList sx={{ p: 0 }}>
          <Stack p={0}>
            <MenuItem onClick={goToProfile}>
              <ListItemIcon>
                <MenuAvatar />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>

            <MenuItem onClick={logoutUser}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Stack>
        </MenuList>
      </Menu>
    </>
  );
};

export default HeaderMenu;
