import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Button } from '@mui/material';
import { useSelector } from 'react-redux';

import { getCompanyPayLinks, setPageTitle } from '@action/company.action';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import NoActivities from '@component/NoActivities';
import {
  selectIsFetchingPayLinks,
  selectKycIsCompletelyVerified,
  selectPayLinks,
} from '@selector/company.selector';
import PayLinkFormDialog from '@page/PayLink/PayLinkFormDialog';
import { fetchApps } from '@action/app.action';

import PayLinkTable from './PayLinkTable';

const PayLink = () => {
  const dispatch = useAppDispatch();
  const payLinks = useSelector(selectPayLinks);
  const isFetchingPayLinks = useAppSelector(selectIsFetchingPayLinks);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const hasData = Boolean(payLinks?.data?.length);

  useEffect(() => {
    dispatch(setPageTitle('PayLinks'));
    dispatch(getCompanyPayLinks({}));
    dispatch(fetchApps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCompletelyVerified = useAppSelector(selectKycIsCompletelyVerified);

  const [openPayLinkDialog, setOpenPayLinkDialog] = useState(false);

  const onClosePayLinkDialog = () => {
    setOpenPayLinkDialog(false);
  };

  const onOpenPayLinkDialog = () => {
    setOpenPayLinkDialog(true);
  };

  return (
    <>
      {!isCompletelyVerified && (
        <Alert severity="warning">
          <AlertTitle sx={{ fontWeight: 600 }}>KYC Required</AlertTitle>
          To use the PayLink feature, users must first complete their KYC
          verification. Please ensure your account is fully verified to access
          this feature.
        </Alert>
      )}

      {!hasData && !isFetchingPayLinks && (
        <NoActivities
          title="No paylink yet"
          subTitle={
            <>
              You currently don’t have any paylink,
              <br />
              once you do it will appear here
            </>
          }
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          {isCompletelyVerified && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onOpenPayLinkDialog}
            >
              Create A PayLink
            </Button>
          )}
        </NoActivities>
      )}

      {hasData && (
        <PayLinkTable
          onOpenPayLinkDialog={onOpenPayLinkDialog}
          searchPerformed={searchPerformed}
          setSearchPerformed={setSearchPerformed}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}

      <PayLinkFormDialog
        open={openPayLinkDialog}
        onClose={onClosePayLinkDialog}
      />
    </>
  );
};

export default PayLink;
