import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { AppTransactionAttributes } from '@interface/appTransaction.interface';
import { useAppDispatch } from '@hook/hooks.hook';
import { setTransactionModalId } from '@action/company.action';

import TransactionStatus from './TransactionStatus';

const Transactions = ({
  transactions,
}: {
  transactions: AppTransactionAttributes[];
}) => {
  const dispatch = useAppDispatch();

  const setTransactionId = (id: string) => {
    dispatch(setTransactionModalId(id));
  };

  return (
    <Box width="100%">
      <Stack
        px={2}
        py={'13px'}
        width="100%"
        direction="row"
        bgcolor="rgba(223, 239, 249, 0.20)"
        alignItems="center"
      >
        <Typography width="25%">Transaction ID</Typography>
        <Typography width="25%">App</Typography>
        <Typography width="25%">Amount</Typography>
        <Typography width="25%">Status</Typography>
      </Stack>

      {transactions.map((transaction) => (
        <Stack
          px={2}
          py={'20px'}
          width="100%"
          direction="row"
          key={transaction.id}
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="grey.50"
          alignItems="center"
          onClick={() => setTransactionId(transaction.id)}
          sx={{
            cursor: 'pointer',
            '&:hover': { bgcolor: 'rgba(223, 239, 249, 0.20)' },
          }}
        >
          <Typography color="grey.800" width="25%" textTransform="uppercase">
            {(transaction.uniqueId?.substring(0, 2) || '') +
              '...' +
              (transaction.uniqueId?.substring(
                transaction?.uniqueId.length - 5,
              ) || '')}
          </Typography>

          <Typography color="grey.800" width="25%">
            {transaction.appName}
          </Typography>

          <Typography color="grey.800" width="25%">
            {transaction.cryptocurrencyAmount.toFixed(2)}{' '}
            {transaction.cryptocurrency}
          </Typography>

          <TransactionStatus status={transaction.status} />
        </Stack>
      ))}
    </Box>
  );
};

export default Transactions;
