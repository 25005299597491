import {
  Button,
  ClickAwayListener,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

import { ReactComponent as SortByIcon } from '@asset/icons/sort.svg';
import { useAppSelector } from '@hook/hooks.hook';
import { selectIsFetchingCompanyAppTransactions } from '@selector/company.selector';

const SortByMenu = ({
  value,
  onSortBy,
}: {
  value: number;
  onSortBy: (value: any) => void;
}) => {
  const [sortByAnchorEl, setSortByAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const openSortByMenu = Boolean(sortByAnchorEl);

  const isFetchingCompanyAppTransactions = useAppSelector(
    selectIsFetchingCompanyAppTransactions,
  );

  const handleOpenSortByMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortByAnchorEl(event.currentTarget);
  };

  const handleCloseSortByMenu = () => {
    setSortByAnchorEl(null);
  };

  const handleSortBy = useCallback(
    ({ target: { value: sortBy } }: any) => {
      onSortBy(sortBy);
      handleCloseSortByMenu();
    },
    [onSortBy],
  );

  return (
    <ClickAwayListener onClickAway={handleCloseSortByMenu}>
      <>
        <Button
          startIcon={<SortByIcon />}
          variant="outlined"
          id="basic-button"
          aria-controls={openSortByMenu ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openSortByMenu ? 'true' : undefined}
          onClick={handleOpenSortByMenu}
          disabled={isFetchingCompanyAppTransactions}
        >
          Sort By
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={sortByAnchorEl}
          open={openSortByMenu}
          onClose={handleCloseSortByMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuList sx={{ width: '180px', p: 0 }}>
            <MenuItem
              onClick={handleSortBy}
              value={0}
              sx={{
                bgcolor: value === 0 ? 'primary.50' : '',
              }}
            >
              Newest First
            </MenuItem>
            <MenuItem
              onClick={handleSortBy}
              value={1}
              sx={{
                bgcolor: value === 1 ? 'primary.50' : '',
              }}
            >
              Oldest First
            </MenuItem>
          </MenuList>
        </Menu>
      </>
    </ClickAwayListener>
  );
};

export default SortByMenu;
