import { Button, Menu, MenuItem, MenuList } from '@mui/material';
import React, { useCallback, useState } from 'react';

import ConfirmationDialog from '@component/ConfirmationDialog';
import SuccessDialog from '@component/SuccessDialog/SuccessDialog';
import { useAppSelector } from '@hook/hooks.hook';
import { selectIsFetchingCompanyAppTransactions } from '@selector/company.selector';

const ExportAs = ({
  hasData,
  onDownloadReport,
  isExportSuccessFull,
  onCloseSuccessModal,
}: {
  hasData: boolean;
  onDownloadReport: () => Promise<void>;
  isExportSuccessFull: boolean;
  onCloseSuccessModal: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const isFetchingCompanyAppTransactions = useAppSelector(
    selectIsFetchingCompanyAppTransactions,
  );

  const isOpen = Boolean(anchorEl);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadCsv = useCallback(() => {
    setOpenConfirmationDialog(true);
    handleCloseMenu();
  }, []);

  const confirmationDialogProps = {
    open: openConfirmationDialog,
    title: 'Export as CSV',
    subtitle: (
      <>
        <div>
          We will send this report to the <b>report notification</b>
        </div>
        <div>
          <b>email address</b> you provided in a few minutes
        </div>
      </>
    ),
    onClose: () => {
      setOpenConfirmationDialog(false);
    },
    onConfirm: onDownloadReport,
    confirmButtonText: 'Proceed',
    cancelButtonText: 'Cancel',
  };

  return (
    <>
      <Button
        variant="outlined"
        id="basic-button"
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        disabled={!hasData || isFetchingCompanyAppTransactions}
        onClick={handleOpenMenu}
      >
        Export As
      </Button>

      {isOpen && (
        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleCloseMenu}
        >
          <MenuList sx={{ width: '180px', p: 0 }}>
            <MenuItem onClick={handleDownloadCsv}>CSV</MenuItem>
          </MenuList>
        </Menu>
      )}

      {confirmationDialogProps.open && (
        <ConfirmationDialog {...confirmationDialogProps} />
      )}

      <SuccessDialog
        title="Export as CSV"
        open={isExportSuccessFull}
        onClose={onCloseSuccessModal}
        successMessage="Request was successful!!!"
        successMessageSubtitle="Your document is being prepared. You will receive an email with the document shortly."
      />
    </>
  );
};

export default ExportAs;
