import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

import successImage from '@asset/images/paylinkdone.webp';

const SuccessDialog = ({
  open,
  title,
  onClose,
  successMessage,
  successMessageSubtitle,
}: {
  open: boolean;
  title: string;
  onClose: () => void;
  successMessage: string;
  successMessageSubtitle?: string;
}) => {
  return open ? (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle mt={4} mb={2} fontFamily="Lato" fontWeight={600}>
        {title}
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 24,
          top: 42,
          color: 'grey.600',
        }}
      >
        <CloseIcon />
      </IconButton>

      <Divider variant="fullWidth" sx={{ width: '92%', m: 'auto' }} />

      <DialogContent>
        <Box textAlign="center" width={350} pb={8}>
          <img width={116} height={'auto'} src={successImage} alt={title} />

          <Typography color="secondary.600" mt={4} mb={2}>
            {successMessage}
          </Typography>

          {successMessageSubtitle && (
            <Typography px={5}>{successMessageSubtitle}</Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  ) : null;
};

export default SuccessDialog;
