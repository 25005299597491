import { Form, Formik } from 'formik';
import { Box, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import FilterFormFields from '@page/AppTransactions/FilterFormFields';
import { disableFilterButton } from '@page/AppTransactions/util';
import { STANDARD_DATE_FORMAT } from '@variable';

import { AdvancedFilterProps } from './index.interface';

const AdvancedFilter = ({
  onClose,
  appOptions,
  onSetFilter,
  onClearFilter,
  initialValues,
  setInitialValues,
}: {
  onClose: () => void;
  initialValues: AdvancedFilterProps;
  onSetFilter: (values: any) => void;
  appOptions: { title: string; value: string }[];
  setInitialValues: (value: AdvancedFilterProps) => void;
  onClearFilter: (setFieldValue: any, onSubmit: any) => void;
}) => {
  const onSubmit = useCallback(
    (values: AdvancedFilterProps) => {
      const fail = disableFilterButton(initialValues, values);

      if (fail) return onClose();

      setInitialValues(values);
      onSetFilter({
        appIds: values.apps,
        startDate: values.startDate?.format(STANDARD_DATE_FORMAT),
        endDate: values.endDate?.format(STANDARD_DATE_FORMAT),
      });
      onClose();
    },
    [initialValues, setInitialValues, onClose, onSetFilter],
  );

  return (
    <Box width="400px">
      <Box px={5} py={1.5} bgcolor="primary.50">
        <Typography fontWeight={500} fontSize={20}>
          Filter By
        </Typography>
      </Box>

      <Box p={5}>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={initialValues}
        >
          {() => (
            <Form>
              <FilterFormFields
                appOptions={appOptions}
                initialValues={initialValues}
                onClearFilter={onClearFilter}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AdvancedFilter;
