import { Navigate } from 'react-router-dom';
import React, { FC } from 'react';

import UserRole from '@enum/userRole.enum';
import { useAppSelector } from '@hook/hooks.hook';
import { selectUserRole } from '@selector/permission.selector';

const RoleBasedPermission: FC<{ children: any; roles: UserRole[] }> = ({
  children,
  roles,
}) => {
  const userRole = useAppSelector(selectUserRole);
  let defaultPath = 'overview';

  if (userRole === UserRole.DEVELOPER) defaultPath = 'apps';
  if (userRole === UserRole.CUSTOMER_SUPPORT) defaultPath = 'transactions';

  return roles.includes(userRole as UserRole) ? (
    children
  ) : (
    <Navigate to={`/dashboard/${defaultPath}`} />
  );
};

export default RoleBasedPermission;
