import { Box } from '@mui/material';
import { Outlet as RouterOutlet } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Header from '@component/DashboardLayout/Header';

const MainContent = () => {
  const ref = useRef<HTMLElement>();
  const [headerWidth, setHeaderWidth] = useState(
    ref.current?.getBoundingClientRect().width as number,
  );

  const handleSetHeaderWidth = useCallback(() => {
    setHeaderWidth(ref.current?.getBoundingClientRect().width as number);
  }, []);

  useEffect(() => {
    setHeaderWidth(ref.current?.getBoundingClientRect().width as number);

    window.addEventListener('resize', handleSetHeaderWidth);

    return () => {
      window.removeEventListener('resize', handleSetHeaderWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="main"
      ref={ref}
      sx={{
        pt: '88px',
        flexGrow: 1,
      }}
    >
      <Header width={headerWidth} />
      <RouterOutlet />
    </Box>
  );
};

export default MainContent;
